.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.team-logo {
  height: 70px;
  width: 70px;
  max-height: 15vw;
  max-width: 15vw;
  border-radius: 50%;
  margin-left: 20px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 40px;
  min-height: 70px;
  background-color: rgb(185, 213, 150);
  z-index: 1000;
  /* box-sizing: border-box; */
}

.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  list-style: none;
}

* {
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */
}

.navMenuText {
  display: block;
  position: relative;
  color: rgb(11, 10, 10);
  text-decoration: none;
  border-radius: 5px;
  transition-duration: 0.2s;
}

.navMenuText:hover {
  text-shadow: 0 0 0.65px #222222, 0 0 0.65px #222222;
  transition-duration: 0.2s;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.sticky + .content {
  padding-top: 60px;
}

.canvas {
  /* background-color: rgb(116, 179, 175); */
}

/* These CSS styles only apply to small screens */
@media screen and (max-width: 1300px) {
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .bar {
    background-color: #120f0b;
    width: 20px;
    height: 3px;
    display: block;
    margin: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .navMenu {
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 75px;
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: rgb(208, 239, 189);
    padding: 10px;
  }

  .navMenu.active {
    left: 0;
  }

  .navMenu li {
    margin: 16px 0;
  }
}

.section-container {
  padding-top: 75px;
}

.section-header {
  text-align: center;
  padding: 10px 0px 20px 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.subsection-header {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.subsection-text {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

/* About section */
.about-section {
  /* background-color: rgb(150, 181, 194); */
}

/* Info Section Styles */
.info-section {
  display: flex;
  margin-left: 20px;
  margin-bottom: 15px;
}

.info-section-container {
  width: 60vw;
}

.info-section-header {
}

.info-section-text {
  width: 80%;
  padding-top: 20px;
}

.info-section-image {
  width: 80%;
  height: auto;
}

/* style={{width: "60vw"}} */

@media screen and (max-width: 780px) {
  .info-section {
    flex-direction: column;
  }

  .info-section-container {
    width: 100%;
    padding: 20px 10px 20px 0px;
  }

  .info-section-text {
    width: 100%;
  }
}

/* Coach Section Styles */

.coaches-section {
  /* background-color: #61dafb; */
}

.coaches-card-section {
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
}

.blockquote {
  border-left: 2px grey solid;
  padding-left: 10px;
}

@media screen and (max-width: 780px) {
  .coaches-card-section {
    flex-direction: column;
    padding: 0px;
    gap: 15px;
    margin: 0 auto;
    align-items: center;
  }
}

/* Gallery content */
.gallery-section {
}

.gallery-carousel {
}

.gallery-item {
  text-align: center;
}

.gallery-image {
  height: 70vh;
  width: auto;
}

@media screen and (max-width: 780px) {
  .gallery-image {
    height: 50vh;
    width: auto;
  }
}

/* Services content  */
.services-section {
  /* border-top: 1px solid rgb(54, 53, 53);
   border-bottom: 1px solid rgb(54, 53, 53) ; */
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.service-container {
}

.service-container-title {
  text-align: center;
  margin-bottom: 10px;
}

.service-container-text {
  text-align: center;
  margin: 0px;
}

.divider {
  height: 2px;
  max-width: 400px;
  width: 60vw;
  background-color: rgb(54, 53, 53);
  margin: 0 auto;
}

/* Partners content */
.partners-section {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.partners-image-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.partner-image {
  height: 300px;
  width: auto;
}

.partners-text-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 50px 200px;
  padding: 0px 300px;
}

@media screen and (max-width: 780px) {
  .partners-image-container {
    flex-direction: column;
    gap: 30px;
    align-items: center;
    display: flex;
  }

  .partner-image {
    width: 80%;
    height: auto;
  }

  .partners-text-container {
    flex-direction: column;
    /* text-align: center; */
    text-align: center;
    padding: 20px;
  }
}

/* Achievements content */

.achievements-container {
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
  padding-left: 200px;
  padding-right: 200px;
}

.achievement-list-container {
  flex: 1;
}

.achievement-list-container-header {
  /* text-align: center; */
}

.achievement-list-container-ul {
  list-style: none;
}

@media screen and (max-width: 780px) {
  .achievements-container {
    padding: 20px;
    flex-direction: column;
  }
}

/* Goals Content */
.goals-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.goal-container {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.goal-number {
  display: flex;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background-color: rgb(80, 194, 145);
  color: white;
  font-size: 20px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-emphasis: 20px;
  margin: 5px;
}

.goal-text {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

/* Contact content */
.contacts-section {
  display: flex;
  flex-direction: column;
}
.contact-container {
  text-align: center;
  margin-bottom: 10px;
}

.contact-component-container {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 0;
  margin-top: 10px;
}

/* Footer content */
.footer {
  width: 100%;
  height: auto;
  background-color: rgb(185, 213, 150);
  text-align: center;
  display: flex;
  padding: 5px;
  font-size: 10px;
}